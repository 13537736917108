'use client';

import { Flex } from '@medsimples/design-system/src/chakra';

export default async function AuthLayout({
  children,
}: {
  children: React.ReactNode,
}) {
  return (
    <div style={{ flexGrow: 1, width: '100%' }}>
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        h="100%"
      >
        {children}
      </Flex>
    </div>
  );
}